import { useTranslation } from 'react-i18next';

// STYLES
import "./Footer.sass";

// IMAGES
import mail from "../../assets/icons/mail.svg";
import phone from "../../assets/icons/phone.svg";
import web from "../../assets/icons/web.svg";
import logo from '../../assets/images/logo.webp';
import logo_en from '../../assets/images/logo_en.webp';

const Footer = () => {
  const { t, i18n } = useTranslation();

  var logo2;
  if (i18n.language === "pl") {
      logo2 = logo
  } else {
    logo2 = logo_en
  }

  return (
    <footer className="footer">
      <div>
        <img className="logo" src={logo2} alt="Cyberbezpieczeństwo" />
        <br />
        <p>BluSoft sp. z o. o.</p>
        <p>Kijowska 44, 85-703 Bydgoszcz</p>
        <p>NIP 5542932079</p>
      </div>
      <div>
        <br />
        <p>
          <b>{t("contact_heading")}</b>
        </p>
        <p>
          <img src={mail} alt="E-mail: " />
          <a href="mailto:handlowy@e-pulpit24.pl">handlowy@e-pulpit24.pl</a>
        </p>
        <p>
          <img src={phone} alt="Telefon: " />
          <a href="tel:+48525222249">(+48) 52 522 22 49</a>
        </p>
        <p>
          <img src={web} alt="Strona główna: " />
          <a href="https://blusoft.pl">blusoft.pl</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
