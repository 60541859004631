// STYLES
import './Heading.sass';

const Heading = ({children, start}) => {
  return (
    <div className={`heading__container heading__container--${start}`}>
      <div className={`heading heading--${start}`}>
        {children}
      </div>
    </div>
    
  )
}

export default Heading;