import { useTranslation } from 'react-i18next';

// STYLES
import './inHomeFrames.sass';

// IMAGES
import uzywamy from '../../assets/images/ocena_wydajnosci_uzywamy.webp';
import arrowright from '../../assets/images/arrow-right.svg';
import blazemeter from '../../assets/images/BlazeMeter.png';
import elasticsearch from '../../assets/images/Elasticsearch_logo.png';
import JMeter from '../../assets/images/Apache_JMeter.png';
import ZABBIX from '../../assets/images/zabbix_logo.jpg';
import kibana from '../../assets/images/pngwing.com.png';
import kali from '../../assets/images/kali.png';
import python from '../../assets/images/python.png';

const WydajnoscFrame = () => {
  const { t } = useTranslation();

  return (
    <div className="frame">
      <h2>{t("system_evaluation_header")}</h2>
      <p>{t("system_evaluation_description")}</p>
      <br />
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>{t("overload_tests")}</th>
              {/* <th>Testy obciążeniowe</th>
              <th>Monitoring</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Stress testing</td>
              {/* <td>Load testing</td>
              <td>Capability</td> */}
            </tr>
            <tr>
              <td>Endurance testing</td>
              {/* <td>Spike testing</td>
              <td>Availability</td> */}
            </tr>
          </tbody>
        </table>
        <br />
        <table>
          <thead>
            <tr>
              {/* <th>Testy przeciążeniowe</th> */}
              <th>{t("load_tests_header")}</th>
              {/* <th>Monitoring</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td>Stress testing</td> */}
              <td>Load testing</td>
              {/* <td>Capability</td> */}
            </tr>
            <tr>
              {/* <td>Endurance testing</td> */}
              <td>Spike testing</td>
              {/* <td>Availability</td> */}
            </tr>
          </tbody>
        </table>
        <br />
        <table>
          <thead>
            <tr>
              {/* <th>Testy przeciążeniowe</th>
              <th>Testy obciążeniowe</th> */}
              <th>{t("monitoring_tests_header")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td>Stress testing</td>
              <td>Load testing</td> */}
              <td>Capability</td>
            </tr>
            <tr>
              {/* <td>Endurance testing</td>
              <td>Spike testing</td> */}
              <td>Availability</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <p>
        {t("performance_testing_paragraph")}
      </p>
      <br />
      <p><b>{t("how_we_work_paragraph")}</b></p>
      <div className="timeline">
        <div className="stamp">
          <div className="stamp__box">
            {t("stamp_box_1")}
          </div>
          <div className="stamp__desc">
            {t("stamp_desc_1")}
          </div>
        </div>
        <img src={arrowright} alt="->" className="arrow" />
        <div className="stamp">
          <div className="stamp__box">
          {t("stamp_box_2")}
          </div>
          <div className="stamp__desc">
          {t("stamp_desc_2")}
          </div>
        </div>
        <img src={arrowright} alt="->" className="arrow" />
        <div className="stamp">
          <div className="stamp__box">
          {t("stamp_box_3")}
          </div>
          <div className="stamp__desc">
          {t("stamp_desc_3")}
          </div>
        </div>
      </div>
      <br />
      <p><b>{t("what_we_use_header")}</b></p>
      <p>
        {t("our_specialists_paragraph")}
      </p>
      <br />
      <div className="logos">
        <img src={blazemeter} alt="BlazeMeter" />
        <img src={elasticsearch} alt="elasticsearch" />
        <img src={JMeter} alt="Apache JMeter" />
        <img src={ZABBIX} alt="ZABBIX" />
        <img src={kibana} alt="Kibana" />
        <img src={kali} alt="kali" />
        <img src={python} alt="python" />
      </div>
    </div>
  )
}

export default WydajnoscFrame;