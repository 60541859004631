import { useTranslation } from 'react-i18next';

// COMPONENTS
import { useState } from 'react';
import Header from '../../Components/Header/Header';

// STYLES
import './Landing.sass';

const Landing = () => {
  const { t } = useTranslation();

  const landingText = [
    t('colecting_data') + "\n\n "+ t('never_reverse'),
    t('solutions'),
    t('solutions_2')
  ]

  const [landingTextIndex, setlandingTextIndex] = useState(0);

  const buttonSetOnClick = (e) => {
    const buttons = document.querySelectorAll('.button-set__button');
    const index = e.target.dataset.index;

    buttons.forEach(button => button.classList.remove('active'));
    buttons[index].classList.add('active');
    setlandingTextIndex(index);
  }

  return (
    <div className="landing">
      <Header />
      <div className="landing__container">
      
        <h1> {t('your_protect')}<br />{t('three_steps')}</h1>
        <div className="button-set">
          <input type="button" className="button-set__button active" value={t('phaste_1')} data-index="0" onClick={buttonSetOnClick} />
          <input type="button" className="button-set__button" value={t('phaste_2')} data-index="1" onClick={buttonSetOnClick} />
          <input type="button" className="button-set__button" value={t('phaste_3')} data-index="2" onClick={buttonSetOnClick} />
        </div>
        <p>
          {landingText[landingTextIndex].split("\n").map((item, index) => {
            return <span key={index}>{item}<br /></span>
            })
          }
        </p>
      </div>
    </div>
  )
}

export default Landing;