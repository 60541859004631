import { useTranslation } from 'react-i18next';

// STYLES
import './inHomeFrames.sass';

const SkalowalnoscFrame = () => {
  const { t } = useTranslation();

  return (
    <div className="frame">
      <h2>{t("potential_problems_header")}</h2>
      <p>{t("performance_reports_paragraph")}</p>
      <ul className="ulist">
        <li><b>{t("lack_of_realistic_production_conditions")}</b><br />{t("performance_and_scalability_tests_limitations")}</li>
        <li><b>{t("lack_of_real_data")}</b><br />{t("use_of_test_data")}</li>
        <li><b>{t("difficulty_identifying_subtle_issues")}</b><br />{t("tests_may_not_detect_subtle_issues")}</li>
        <li><b>{t("focus_on_one_aspect")}</b><br />{t("performance_and_scalability_tests_focus_on_one_aspect")}</li>
        <li><b>{t("costs_and_limited_resources")}</b><br />{t("conducting_comprehensive_performance_tests")}</li>
        <li><b>{t("lack_of_production_success_guarantee")}</b><br />{t("tests_identify_potential_problems")}</li>
      </ul>
    </div>
  )
}

export default SkalowalnoscFrame;