// FRAMEWORKS
import { useEffect, useState } from 'react';

// STYLES
import './SlidingMenu.sass';

/**
 * SlidingMenu component provides a navigation menu with sliding animation and content box below.
 * @param {Object} props - The component's properties.
 * @param {Object} props.content - Object containing name and JSX.Element of the content.
 * @param {string} props.content.name - Name of the content.
 * @param {string} props.content.href - href of the content.
 * @param {JSX.Element} props.content.component - JSX.Element of the content.
 * @return {JSX.Element} Component
 */
const SlidingMenu = ({ content }) => {
  // STATE
  const [currentIndex, setCurrentIndex] = useState(0);

  // METHODS
  const onNavHover = (event) => {
		const offsets = document.querySelector(".nav").getBoundingClientRect();
		if (!(event.target.className === "nav__option" || event.target.className === "nav__option--active")) return;
		const indicator = document.querySelector(".nav__indicator--hover");
		indicator.style.background = "#032648";
		indicator.style.left = `${event.target.getBoundingClientRect().left - offsets.left}px`;
		indicator.style.top = `${event.target.getBoundingClientRect().top - offsets.top}px`;
		indicator.style.width = `${event.target.getBoundingClientRect().width}px`;
	}

  const onNavHoverLeave = () => {
		const indicator = document.querySelector(".nav__indicator--hover");
		indicator.style.background = "";
	}

  const onNavOptionChange = (event) => {
		const offsets = document.querySelector(".nav").getBoundingClientRect();
		if (!(event.target.className === "nav__option" || event.target.className === "nav__option--active")) return;
		
		document.querySelector(".nav__option--active").className = "nav__option";
		const indicator = document.querySelector(".nav__indicator");
		indicator.style.left = `${event.target.getBoundingClientRect().left - offsets.left}px`;
		indicator.style.top = `${event.target.getBoundingClientRect().top - offsets.top}px`;
		indicator.style.width = `${event.target.getBoundingClientRect().width}px`;
		event.target.className = "nav__option--active";
		setCurrentIndex(event.target.dataset.index);
	}

  const onResize = () => {
		const lastHoverElem = document.querySelector(".nav__option--active");
		const offsets = document.querySelector(".nav").getBoundingClientRect();
		const indicator = document.querySelector(".nav__indicator");
		const hoverIndicator = document.querySelector(".nav__indicator");
		indicator.style.left = `${lastHoverElem.getBoundingClientRect().left - offsets.left}px`;
		indicator.style.top = `${lastHoverElem.getBoundingClientRect().top - offsets.top}px`;
		indicator.style.width = `${lastHoverElem.getBoundingClientRect().width}px`;
		hoverIndicator.style.left = `${lastHoverElem.getBoundingClientRect().left - offsets.left}px`;
		hoverIndicator.style.top = `${lastHoverElem.getBoundingClientRect().top - offsets.top}px`;
		hoverIndicator.style.width = `${lastHoverElem.getBoundingClientRect().width}px`;
	}

  const onHashChange = (event) => {
    const hash = event.newURL.split("/")[3]
    if (hash === "" || hash == "#") return;
    const option = document.querySelector(event.newURL.split("/")[3]);
    if (option) option.click();
    window.scrollTo(0, window.scrollY - 100);
  }

  const onLoad = () => {
    // set first or based on hash element to nav__option--active
    const hash = window.location.hash;
    try {
      if (hash) document.querySelector(hash).click();
      else document.querySelector(".nav__option").className = "nav__option--active";
    } catch (err) {
      const option = document.querySelectorAll(".nav__option");
      if (option.length > 0) option[0].className = "nav__option--active";
    }
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    window.addEventListener("hashchange", onHashChange);
    window.addEventListener("load", onLoad)
    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("hashchange", onHashChange);
      window.removeEventListener("load", onLoad);
    }
  }, []);

  // RENDER
  return (
    <>
      <nav className="nav" onMouseMove={onNavHover} onMouseLeave={onNavHoverLeave} onClick={onNavOptionChange}>
        <div className="nav__indicator--hover"></div>
        <div className="nav__indicator"></div>
        {
          content.map((option, index) => {
            return <a id={option.href} key={index} className={index === 0 ? "nav__option--active" : "nav__option"} data-index={index} href={"#" + option.href}>{option.name}</a>
          })
        }
      </nav>
      <div className="sliding-menu__content">
        {content[currentIndex].component}
      </div>
    </>
  )
}

export default SlidingMenu;