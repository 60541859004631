// STYLES
import './OfferCard.sass';

const OfferCard = ({icon, title, description}) => {
  return (
    <div className="offer-card">
      <div>
        <img src={icon} alt="icon" />
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  )
}

export default OfferCard;