import { useTranslation } from 'react-i18next';

// STYLES
import './inHomeFrames.sass';

const OgraniczeniaFrame = () => {
  const { t } = useTranslation();

  return (
    <div className="frame">
      <h2>{t("indicating_limitations")}</h2>
      <p>{t("all_tests_end_with_report")}</p>
      <br />
      <p><b>{t("example_of_conclusion_from_report")}</b></p>
      <p>5/10
        {t("system_moderate_scalability")}</p>
    </div>
  )
}

export default OgraniczeniaFrame;